import React, { useState, useEffect } from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, TablePagination } from '@mui/material';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../../components/Firebase';
import ManageUniversity from './ManageUniversity';
import { GrFormPreviousLink } from "react-icons/gr";
import { useNavigate, Link } from 'react-router-dom';

const UniversityList = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [currentUniversity, setCurrentUniversity] = useState(null);
  const [universities, setUniversities] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Fetch universities from Firestore
  useEffect(() => {
    const fetchUniversities = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'universities'));
        const universityData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setUniversities(universityData);
      } catch (error) {
        console.error('Error fetching universities:', error);
      }
    };

    fetchUniversities();
  }, []);

  const handleEdit = (university) => {
    setCurrentUniversity(university);
    setOpenDialog(true);
  };

  const handleCreateUniversity = () => {
    setCurrentUniversity(null);
    setOpenDialog(true);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Filter universities based on search term
  const filteredUniversities = universities.filter((university) =>
    university.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    university.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
    university.state.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Paginate universities
  const paginatedUniversities = filteredUniversities.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <div style={{ padding: '20px', paddingTop: '80px' }}>
      <div style={{ position: 'fixed', top: '20px', left: '20px', display: 'flex', alignItems: 'center' }}>
        <Link to="/">
          <GrFormPreviousLink /> Home
        </Link>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <h2>University List</h2>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateUniversity}
        >
          Create University
        </Button>
      </div>

      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={handleSearch}
      />

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>University Name</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedUniversities.map((university) => (
              <TableRow key={university.id}>
                <TableCell>{university.name}</TableCell>
                <TableCell>{`${university.addressLine1}, ${university.city}, ${university.zipCode} ${university.state}`}</TableCell>
                <TableCell>
                  <Button variant="outlined" onClick={() => handleEdit(university)}>
                    Edit
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={filteredUniversities.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* ManageUniversity dialog */}
      <ManageUniversity open={openDialog} handleClose={() => setOpenDialog(false)} university={currentUniversity} />
    </div>
  );
};

export default UniversityList;
