import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import FileUpload from './FileUpload';
import Login from './pages/Login';
import Header from './components/Header';
import UniversityList from './pages/admin/universities/UniversityList';
import UsersList from './pages/admin/users/UsersList';
import { useUser } from './components/useUser';
import ForgotPassword from './pages/ForgotPassword';
import ChangePassword from './pages/ChangePassword';

const App = () => {
  const { currentUser, loading } = useUser(); // Getting the current user role

  // Protected route wrapper component
  const ProtectedRoute = ({ children }) => {
    return currentUser ? children : <Navigate to="/login" replace />;
  };

  // While loading, you can return a loading spinner or nothing
  if (loading) {
    return <div>Loading...</div>; // Or any loading component
  }

  return (
    <div className="App">
      <Router>
        {/* Conditionally render Header only when the user is authenticated */}
        {currentUser && <Header />}

        <Routes>
          <Route path="/login" element={<Login />} />

          {/* Protected routes */}
          <Route path="/" element={<ProtectedRoute><FileUpload /></ProtectedRoute>} />
          <Route path="/admin/universities" element={<ProtectedRoute><UniversityList /></ProtectedRoute>} />
          <Route path="/admin/users" element={<ProtectedRoute><UsersList /></ProtectedRoute>} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/change-password" element={<ChangePassword />} />
          {/* Redirect to login for any undefined route */}
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
