// src/components/useUser.js

import { useEffect, useState } from 'react';
import { auth } from '../components/Firebase'; // Import your auth object
import { onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

export const useUser = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userRole, setUserRole] = useState(null); // New state to hold the user's role
  const [loading, setLoading] = useState(true);
  const db = getFirestore(); // Initialize Firestore

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);
      setLoading(true); // Start loading while fetching role

      if (user) {
        // Fetch the user role from Firestore
        const userDoc = doc(db, 'users', user.email); // Assuming user document ID is the user's UID
        const userSnapshot = await getDoc(userDoc);

        if (userSnapshot.exists()) {
          setUserRole(userSnapshot.data().role); // Assuming the role field is named 'role'
        } else {
          console.log('No such document!');
        }
      } else {
        setUserRole(null); // No user logged in, reset the role
      }
      setLoading(false); // Finished loading
    });

    return () => unsubscribe();
  }, [db]);

  return { currentUser, userRole, loading };
};
