import React, { useEffect, useState } from 'react';
import { getAuth, reauthenticateWithCredential, EmailAuthProvider, updatePassword } from 'firebase/auth';
import { TextField, Button, Box, Typography, Alert } from '@mui/material';
import { GrFormPreviousLink } from "react-icons/gr";
import { useNavigate, Link } from 'react-router-dom';

const ChangePassword = () => {
    const auth = getAuth();
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loggedInUserEmail, setLoggedInUserEmail] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        setLoggedInUserEmail(localStorage.getItem('loggedInUserEmail'));
    }, []);

    const handlePasswordChange = async (e) => {
        e.preventDefault();
        setError(null);
        setSuccess(null);

        // Check if the new password and confirm password match
        if (newPassword !== confirmPassword) {
            setError("New Password and Confirm Password do not match.");
            return;
        }

        // Ensure user is logged in
        const user = auth.currentUser;
        if (!user) {
            setError("No user is logged in.");
            return;
        }

        try {
            // Step 1: Re-authenticate the user with their current password
            const credential = EmailAuthProvider.credential(loggedInUserEmail, currentPassword);
            await reauthenticateWithCredential(user, credential);

            // Step 2: Update the password
            await updatePassword(user, newPassword);
            setSuccess("Password updated successfully!");
            setCurrentPassword('');
            setNewPassword('');
            setConfirmPassword('');

        } catch (error) {
            setError("Failed to update password: " + error.message);
        }
    };

    return (
        <Box
            sx={{
                maxWidth: 400,
                margin: '0 auto',
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                paddingTop: '120px'
            }}
        >
            <div style={{ position: 'fixed', top: '20px', left: '20px', display: 'flex', alignItems: 'center' }}>
                <Link to="/">
                    <GrFormPreviousLink /> Home
                </Link>
            </div>
            <Typography variant="h5" textAlign="center">Change Password</Typography>

            {error && <Alert severity="error">{error}</Alert>}
            {success && <Alert severity="success">{success}</Alert>}

            <form onSubmit={handlePasswordChange}>
                <TextField
                    label="Current Password"
                    type="password"
                    fullWidth
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    required
                    sx={{ marginBottom: 2 }}
                />
                <TextField
                    label="New Password"
                    type="password"
                    fullWidth
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                    sx={{ marginBottom: 2 }}
                />
                <TextField
                    label="Confirm New Password"
                    type="password"
                    fullWidth
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                    sx={{ marginBottom: 2 }}
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={!currentPassword || !newPassword || !confirmPassword}
                >
                    Update Password
                </Button>
            </form>
        </Box>
    );
};

export default ChangePassword;
