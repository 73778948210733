import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Grid,
    Snackbar,
    Input,
} from '@mui/material';
import { getFirestore, collection, addDoc, doc, updateDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const ManageUniversity = ({ open, handleClose, university }) => {
    const [universityName, setUniversityName] = useState(university ? university.name : '');
    const [addressLine1, setAddressLine1] = useState(university ? university.addressLine1 : '');
    const [addressLine2, setAddressLine2] = useState(university ? university.addressLine2 : '');
    const [city, setCity] = useState(university ? university.city : '');
    const [state, setState] = useState(university ? university.state : '');
    const [zipCode, setZipCode] = useState(university ? university.zipCode : '');
    const [logo, setLogo] = useState(null); // state for the logo file
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [currentLogoUrl, setCurrentLogoUrl] = useState(university ? university.logoUrl : ''); // for editing

    const db = getFirestore();
    const storage = getStorage();

    const handleLogoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setLogo(file); // Store the selected file in state
        }
    };

    useEffect(() => {
        if (university) {
            setUniversityName(university.name);
            setAddressLine1(university.addressLine1);
            setAddressLine2(university.addressLine2);
            setCity(university.city);
            setState(university.state);
            setZipCode(university.zipCode);
            setCurrentLogoUrl(university.logoUrl);
        }
    }, [university]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // If logo is selected, upload it to Firebase Storage
        let logoUrl = currentLogoUrl;
        if (logo) {
            try {
                const logoRef = ref(storage, `university_logos/${logo.name}`);
                const uploadResult = await uploadBytes(logoRef, logo);
                logoUrl = await getDownloadURL(uploadResult.ref); // Get the URL of the uploaded logo
            } catch (error) {
                console.error('Error uploading logo:', error);
                setSnackbarMessage('Failed to upload logo.');
                setSnackbarOpen(true);
                return;
            }
        }

        try {
            if (university) {
                // Edit existing university in Firestore
                const universityRef = doc(db, 'universities', university.id);
                await updateDoc(universityRef, {
                    name: universityName,
                    addressLine1: addressLine1,
                    addressLine2: addressLine2,
                    city: city,
                    state: state,
                    country: 'USA',
                    zipCode: zipCode,
                    logoUrl: logoUrl, // Update the logo URL
                });
                setSnackbarMessage('University updated successfully!');
            } else {
                // Add a new university to Firestore
                await addDoc(collection(db, 'universities'), {
                    name: universityName,
                    addressLine1: addressLine1,
                    addressLine2: addressLine2,
                    city: city,
                    state: state,
                    country: 'USA',
                    zipCode: zipCode,
                    logoUrl: logoUrl, // Store the URL of the logo
                });
                setSnackbarMessage('University created successfully!');
            }

            setSnackbarOpen(true);
            handleClose(); // Close the dialog
        } catch (error) {
            console.error('Error saving university:', error);
            setSnackbarMessage('Failed to save university.');
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{university ? 'Edit University' : 'Create University'}</DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label="University Name"
                                variant="outlined"
                                fullWidth
                                required
                                value={universityName}
                                onChange={(e) => setUniversityName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Address Line 1"
                                variant="outlined"
                                fullWidth
                                required
                                value={addressLine1}
                                onChange={(e) => setAddressLine1(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Address Line 2"
                                variant="outlined"
                                fullWidth
                                value={addressLine2}
                                onChange={(e) => setAddressLine2(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="City"
                                variant="outlined"
                                fullWidth
                                required
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="State"
                                variant="outlined"
                                fullWidth
                                required
                                value={state}
                                onChange={(e) => setState(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Zip Code"
                                variant="outlined"
                                fullWidth
                                required
                                value={zipCode}
                                onChange={(e) => setZipCode(e.target.value)}
                            />
                        </Grid>

                        {/* File input for logo */}
                        <Grid item xs={12}>
                            <Input
                                type="file"
                                accept="image/*"
                                onChange={handleLogoChange}
                                fullWidth
                            />
                        </Grid>
                        {currentLogoUrl && !logo && (
                            <Grid item xs={12}>
                                <img
                                    src={currentLogoUrl}
                                    alt="Current Logo"
                                    style={{ width: '100px', height: 'auto', marginTop: '10px' }}
                                />
                            </Grid>
                        )}
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    {university ? 'Update' : 'Create'}
                </Button>
            </DialogActions>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
            />
        </Dialog>
    );
};

export default ManageUniversity;
