import React from 'react';
const indUSAGrades = require('../assets/indi_usa_grades.jpg');
const cgpaPercentage = require('../assets/cgpa-percentage.png');
const percentageCgpa = require('../assets/percentage-cgpa.png');
const percentageToGpa = require('../assets/gpa40-percentage.png');
const sgpaToCgpa = require('../assets/sgpa_to_cgpa.png');

const IndiaGrades = () => {
  return (
    <div style={{ paddingBottom: '30px', backgroundColor: '#f9f9f9', padding: '24px' }}>
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <img src={indUSAGrades} style={{ maxWidth: '80%', borderRadius: '8px' }} alt="India to USA Grades" />
      </div>

      <div className="section" style={styles.section}>
        <div style={styles.textContent}>
          <h2 style={styles.heading}>How to Convert CGPA to Percentage</h2>
          <p style={styles.paragraph}>
            To convert your CGPA (Cumulative Grade Point Average) to a percentage, simply multiply your CGPA by 9.5.
            <br />
            For example, if your CGPA is 9.2, your percentage will be 87.4%, calculated as 9.2 × 9.5.
          </p>
          <h4 style={styles.subHeading}>Steps to Calculate CGPA and Percentage:</h4>
          <ol style={styles.list}>
            <li style={styles.listItem}>Add the grade points obtained in each subject.</li>
            <li style={styles.listItem}>Divide the total by the number of subjects.</li>
            <li style={styles.listItem}>The result is your CGPA.</li>
            <li style={styles.listItem}>To convert the CGPA into a percentage, multiply it by 9.5.</li>
          </ol>
        </div>
        <div style={styles.imageWrapper}>
          <img src={cgpaPercentage} style={styles.image} alt="CGPA to Percentage" />
        </div>
      </div>

      <div className="section" style={styles.section}>
        <div style={styles.textContent}>
          <h2 style={styles.heading}>How to Calculate CGPA from Percentage</h2>
          <p style={styles.paragraph}>
            Calculate CGPA by dividing your percentage by 9.5. For example, if you scored 90% in the exams, your CGPA will be 90/9.5.
          </p>
        </div>
        <div style={styles.imageWrapper}>
          <img src={percentageCgpa} style={styles.image} alt="Percentage to CGPA" />
        </div>
      </div>

      <div className="section" style={styles.section}>
        <div style={styles.textContent}>
          <h2 style={styles.heading}>How to Convert Percentage to GPA</h2>
          <p style={styles.paragraph}>
            To calculate your GPA (4.0 scale) from your percentage, divide your percentage by 25. For example, if the percentage is 90%, the equivalent GPA will be 3.6.
          </p>
        </div>
        <div style={styles.imageWrapper}>
          <img src={percentageToGpa} style={styles.image} alt="Percentage to GPA" />
        </div>
      </div>

      <div className="section" style={styles.section}>
        <div style={styles.textContent}>
          <h2 style={styles.heading}>How to Calculate Final CGPA from SGPA</h2>
          <p style={styles.paragraph}>
            To calculate your final CGPA (Cumulative Grade Point Average) from your SGPA (Semester Grade Point Average):
          </p>
          <ol style={styles.list}>
            <li style={styles.listItem}>Add the SGPA of all semesters.</li>
            <li style={styles.listItem}>Divide by the total number of semesters to get your CGPA.</li>
          </ol>
        </div>
        <div style={styles.imageWrapper}>
          <img src={sgpaToCgpa} style={styles.image} alt="SGPA to CGPA" />
        </div>
      </div>
    </div>
  );
};

const styles = {
  section: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '30px',
    padding: '20px',
    borderRadius: '8px',
    backgroundColor: '#ffffff',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  textContent: {
    width: '60%',
    paddingRight: '15px',
  },
  imageWrapper: {
    width: '40%',
    marginLeft: '15px',
    display: 'flex',
    justifyContent: 'center',
  },
  image: {
    maxWidth: '100%',
    borderRadius: '8px',
  },
  heading: {
    fontSize: '1.5em',
    margin: '0 0 10px',
    color: '#333',
  },
  subHeading: {
    fontSize: '1.2em',
    marginTop: '15px',
    color: '#555',
  },
  paragraph: {
    lineHeight: '1.6',
    fontSize: '1em',
    color: '#666',
  },
  list: {
    lineHeight: '1.8',
    color: '#555',
  },
  listItem: {
    maxWidth: '100%',
    lineHeight: 2.5,
    display: 'block',
    paddingLeft: '18px'
  }
};

export default IndiaGrades;
