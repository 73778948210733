import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Snackbar,
  Alert,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material';
import { getFirestore, doc, setDoc, getDoc, updateDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { getAuth, createUserWithEmailAndPassword, fetchSignInMethodsForEmail } from 'firebase/auth';
import { sendPasswordResetEmail } from 'firebase/auth';

const ManageUser = ({ open, handleClose, user }) => {
  const [name, setName] = useState(user ? user.name : '');
  const [email, setEmail] = useState(user ? user.email : '');
  const [phoneNumber, setPhoneNumber] = useState(user ? user.phoneNumber : '');
  const [university, setUniversity] = useState(user ? user.university : '');
  const [universities, setUniversities] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [loggedInUserRole, setLoggedInUserRole] = useState(''); // New state for logged-in user's role
  const [loggedInUserEmail, setLoggedInUserEmail] = useState('');

  const db = getFirestore();
  const auth = getAuth();

  // Fetch universities
  // useEffect(() => {
  //   const fetchUniversities = async () => {
  //     const universitiesCollection = collection(db, 'universities');
  //     const universitySnapshot = await getDocs(universitiesCollection);
  //     const universityList = universitySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  //     setUniversities(universityList);
  //   };
  //   fetchUniversities();
  // }, [db]);

  useEffect(() => {
    const fetchUniversities = async () => {
      const role = localStorage.getItem('loggedInUserRole');
      const email = localStorage.getItem('loggedInUserEmail');
      setLoggedInUserRole(role);
      setLoggedInUserEmail(email);

      let universitiesQuery;

      try {
        if (role === 'superAdmin') {
          universitiesQuery = collection(db, 'universities');
          const universitySnapshot = await getDocs(universitiesQuery);
          const universityList = universitySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
          setUniversities(universityList);
        } else if (role === 'universityAdmin') {
          // Query for the logged-in user's data to get their associated university
          const usersQuery = query(collection(db, 'users'), where('email', '==', email));
          const userSnapshot = await getDocs(usersQuery);

          if (userSnapshot.empty) {
            console.error("No user found with the given email.");
            setSnackbarMessage("User data not found. Please check your access permissions.");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
          }

          const userData = userSnapshot.docs[0].data();
          const universityId = userData?.university;

          if (!universityId) {
            console.error("No university ID found for this user.");
            setSnackbarMessage("User university data is missing.");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
          }

          //universitiesQuery = query(collection(db, 'universities'), where('id', '==', universityId));
          const universityDoc = await getDoc(doc(db, 'universities', universityId));
          if (universityDoc.exists()) {
            const universityData = universityDoc.data();
            universityData.id = universityId
            setUniversities([universityData])
          }
        }


      } catch (error) {
        console.error("Error fetching universities:", error);
        setSnackbarMessage("Failed to load universities.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    };

    fetchUniversities();
  }, []);


  useEffect(() => {
    if (user) {
      setName(user ? user.name : '')
      setEmail(user ? user.email : '')
      setPhoneNumber(user ? user.phoneNumber : '')
      setUniversity(user ? user.university : '')
    }
  }, [user])

  // Fetch the logged-in user's role
  useEffect(() => {
    setLoggedInUserRole(localStorage.getItem('loggedInUserRole'))

  }, []);

  // Check if email already exists in authentication
  const checkEmailExists = async () => {
    const methods = await fetchSignInMethodsForEmail(auth, email);
    return methods.length > 0;
  };

  // Handle Submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!user) {
      // Check if email already exists to avoid duplicates
      if (await checkEmailExists()) {
        setSnackbarMessage('Email already exists.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return;
      }
    }

    // Restrict role assignment based on logged-in user's role
    let assignedRole = '';
    if (loggedInUserRole === 'superAdmin') {
      assignedRole = 'universityAdmin';
    } else if (loggedInUserRole === 'universityAdmin') {
      assignedRole = 'universityUser';
    } else {
      setSnackbarMessage('You do not have permission to create or update this user.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    const password = user ? null : Math.random().toString(36).slice(-8);

    try {
      if (!user) {
        // Create new user
        await createUserWithEmailAndPassword(auth, email, password);
        await sendPasswordResetEmail(auth, email);
        
      }

      const userRef = doc(db, 'users', email);

      // Update or Set user data in Firestore
      const userData = {
        email,
        name,
        phoneNumber,
        university,
        role: assignedRole,
        createdBy: auth.currentUser.email,
        createdAt: user ? user.createdAt : new Date(),
      };

      if (user) {
        await updateDoc(userRef, userData); // Update existing user data
      } else {
        await setDoc(userRef, userData); // Set data for new user
      }

      setSnackbarMessage(user ? 'User updated successfully!' : 'User created successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      handleClose();
    } catch (error) {
      console.error('Error saving user:', error);
      setSnackbarMessage('Failed to save user.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{user ? 'Edit User' : 'Create User'}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{ marginBottom: '16px' }}
          />
          <TextField
            label="Email"
            type="email"
            variant="outlined"
            fullWidth
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={user !== null}
            sx={{ marginBottom: '16px' }}
          />
          <TextField
            label="Phone Number"
            variant="outlined"
            fullWidth
            required
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            sx={{ marginBottom: '16px' }}
          />
          <FormControl variant="outlined" fullWidth required>
            <InputLabel>University</InputLabel>
            <Select
              value={university}
              onChange={(e) => setUniversity(e.target.value)}
              label="University"
              sx={{ marginBottom: '16px' }}
            >
              {universities.map((uni) => (
                <MenuItem key={uni.id} value={uni.id}>
                  {uni.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          {user ? 'Update' : 'Create'}
        </Button>
      </DialogActions>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        dir='top'
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default React.memo(ManageUser);
