export function calculateCGPA(sgpaValues) {
    if (sgpaValues.length === 0) {
        return null; // Return null if there are no SGPA values
    }

    // Sum all SGPA values and divide by the number of semesters
    const total = sgpaValues.reduce((acc, sgpa) => acc + sgpa, 0);
    const cgpa = total / sgpaValues.length;

    return cgpa.toFixed(2); // Return CGPA rounded to 2 decimal places
}


export function extractFirstOverallCGPA(text) {
    // Regular expression to match "Overall CGPA of " followed by a decimal number
    let regex = /Overall CGPA of\s*([0-9]*\.?[0-9]+)/i;

    // Use exec to find the first occurrence that matches the pattern
    let match = regex.exec(text);
    let overallCGPAValue = null;

    // Check if a match was found and extract the CGPA value
    if (match) {
        overallCGPAValue = parseFloat(match[1]);
    }

    console.log(overallCGPAValue);
    return overallCGPAValue;
}


// export function extractAllSGPA(text) {
//     // Regular expression to match all instances of "SGPA(Semester Grade Point Average) :" followed by a number
//     const regex = /SGPA\(Semester Grade Point Average\)\s*:\s*([0-9]*\.?[0-9]+)/g;
//     const matches = [...text.matchAll(regex)]; // Use matchAll to find all occurrences

//     let sgpaValues = 0

//     // Extract the SGPA values, convert to float, and filter those <= 10
//     if(matches && matches.length > 0 ) {
//         sgpaValues = matches
//         .map(match => parseFloat(match[1]))
//         .filter(sgpa => sgpa <= 10);
//     } else {
//         const regex = /Semester Grade Point Average\(SGPA\)\s*=\s*([0-9]*\.?[0-9]+)/g;
//         const matches = [...text.matchAll(regex)]; 
//         sgpaValues = matches
//         .map(match => parseFloat(match[1]))
//         .filter(sgpa => sgpa <= 10);
//     }

//     return sgpaValues;
// }

export function extractAllSGPA(text) {
    // Regular expression to match both formats:
    // 1. "Semester Grade Point Average (SGPA)"
    // 2. "SGPA (Semester Grade Point Average)"

    // Regular expression to match all instances of "SGPA(Semester Grade Point Average) :" followed by a number
    let regex = /SGPA\(Semester Grade Point Average\)\s*:\s*([0-9]*\.?[0-9]+)/g;
    let matches = [...text.matchAll(regex)];
    let sgpaValues = [];

    // Extract the SGPA values, convert to float, and filter those <= 10
    if (matches && matches.length > 0) {
        sgpaValues = matches
            .map(match => parseFloat(match[1]))
            .filter(sgpa => sgpa <= 10);
    } else {
        regex = /(Semester Grade Point Average\s*\(SGPA\)|SGPA\s*\(Semester Grade Point Average\))\s*=\s*(\*+)?\s*([0-9]*\.?[0-9]+)/g;

        // Use matchAll to find all occurrences that match the pattern
        matches = [...text.matchAll(regex)];
        if (matches && matches.length > 0) {
            sgpaValues = matches
                .map(match => parseFloat(match[3])) // Using match[3] to capture the SGPA value (after ignoring **** if present)
                .filter(sgpa => sgpa <= 10);
        } else {
            regex = /SGPA\s*:\s*([0-9]*\.?[0-9]+)/g;
            matches = [...text.matchAll(regex)];
            if (matches && matches.length > 0) {
                sgpaValues = matches
                    .map(match => parseFloat(match[3])) // Using match[3] to capture the SGPA value (after ignoring **** if present)
                    .filter(sgpa => sgpa <= 10);
            }
            console.log("sgpaValues ==> ", sgpaValues)
        }
    }

    return sgpaValues;
}

export function extractAllCGPA(text) {
    // Regular expression to match "CGPA" optionally followed by "Secured" and the CGPA value
    let regex = /CGPA\s*(?:Secured)?\s*[:\s]?\s*(\d+\.\d+)/gi;

    // Use matchAll to find all occurrences that match the pattern
    let matches = [...text.matchAll(regex)];
    let cgpaValues = [];

    // Extract the CGPA values and convert them to float
    if (matches && matches.length > 0) {
        cgpaValues = matches.map(match => parseFloat(match[1]));
    }

    console.log(cgpaValues);
    return cgpaValues;
}

export function convertPercentageToGPA(percentage) {
    const maxPercentage = 100;
    const maxGPA = 4;
    let per = parseFloat(percentage);

    //let gpa = (per / maxPercentage) * maxGPA;
    let gpa = per / 25
    return gpa.toFixed(2);
};

export function getPerFromCGPA(cgpa) {
    let per = cgpa * 9.5
    return per.toFixed(2);
}

export function getCGPAFromPer(per) {
    let cgpa = per / 9.5
    return cgpa.toFixed(2);
}

export function getClassification(percentage) {
    const roundedPercentage = Math.round(percentage);
    if (roundedPercentage >= 70) {
        return 'First Class with Distinction';
    } else if (roundedPercentage >= 60) {
        return 'First Class';
    } else if (roundedPercentage >= 50) {
        return 'Second Class';
    } else if (roundedPercentage >= 40) {
        return 'Third Class';
    } else {
        return 'Fail';
    }
}

export function getUSClassification(percentage) {
    const roundedPercentage = Math.round(percentage); // Round to nearest integer
    if (roundedPercentage >= 60) {
        return 'A';
    } else if (roundedPercentage >= 50) {
        return 'B';
    } else if (roundedPercentage >= 40) {
        return 'C';
    } else {
        return 'F';
    }
}


export function extractCGPAFromNagarjunaUniversity(content) {
    // Define the marker patterns to identify the section containing CGPA values
    const provisionalCertificatePattern = /PROVISIONAL CERTIFICATE([\s\S]*?)Nagarjuna Nagar/;
    const regNoPattern = /Reg\. No\.:([\s\S]*?)Given under the Seal of the University/;

    // Extract content between specified markers
    let matchedContent = content.match(provisionalCertificatePattern) || content.match(regNoPattern);
    if (!matchedContent) {
        console.log("No valid content found between specified markers.");
        return null;
    }

    // Extracted section containing CGPA information
    const targetContent = matchedContent[1];

    // Regular expression to match CGPA values (decimal numbers)
    const cgpaPattern = /\b\d+\.\d{2}\b/g;
    const cgpaValues = targetContent.match(cgpaPattern);
    console.log("cgpaValues ==>", cgpaValues)
    if (cgpaValues && cgpaValues.length > 0) {
        // Convert values to numbers and calculate the average CGPA
        const numericCgpaValues = cgpaValues.map(Number);
        const averageCgpa = numericCgpaValues.reduce((sum, cgpa) => sum + cgpa, 0) / numericCgpaValues.length;
        return parseFloat(averageCgpa.toFixed(2)); // Return average CGPA rounded to 2 decimal places
    } else {
        console.log("No CGPA values found in the specified content.");
        return null;
    }
}

export function extractCGPAFromSSC(text) {
    const match = text.match(/Cumulative Grade Point Average \(CGPA\):[\s\S]*?(\d+\.\d+)/);
    return match ? parseFloat(match[1]) : null;
}

export function extractTotalMarksFromPUC(text) {
    const match = text.match(/Total Marks\s*[\s\S]*?(\d+)/);
    return match ? parseInt(match[1], 10) : null;
}