// src/components/Header.js

import React, { useState, useEffect } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { useUser } from '../components/useUser';
import { useNavigate } from 'react-router-dom';
import { Avatar, Menu, MenuItem } from '@mui/material';

const Header = () => {
  const auth = getAuth();
  const { currentUser, loading } = useUser();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loggedInUserRole, setLoggedInUserRole] = useState('');

  useEffect(() => {
    setLoggedInUserRole(localStorage.getItem('loggedInUserRole'))
  }, []);

  const handleLogout = () => {
    signOut(auth)
      .then(() => console.log('User signed out.'))
      .catch(console.error);
    navigate('/login')
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const navigateTo = (path) => {
    handleMenuClose();
    navigate(path);
  };

  if (loading) return <p>Loading...</p>;

  return (
    <div style={{ position: 'absolute', right: '10px', top: '10px', zIndex: 999 }}>
      

      {/* Avatar for the dropdown menu */}
      {currentUser && (
        <div>
          <Avatar onClick={handleMenuClick} style={{ cursor: 'pointer', backgroundColor: '#fff', color: '#dc2a4c' }} />
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
            {loggedInUserRole == 'superAdmin' && <MenuItem onClick={() => navigateTo('/admin/universities')}>Universities</MenuItem>}
            {loggedInUserRole !== 'superAdmin' && loggedInUserRole !== 'universityUser' && <MenuItem onClick={() => navigateTo('/admin/users')}>Users</MenuItem>}
            <MenuItem onClick={() => navigateTo('/change-password')}>Change Password</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </div>
      )}
    </div>
  );
};

export default Header;
