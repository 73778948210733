import React, { useEffect, useState } from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, TablePagination } from '@mui/material';
import { getFirestore, collection, getDocs, query, where } from 'firebase/firestore';
import ManageUser from './ManageUser';
import { GrFormPreviousLink } from "react-icons/gr";
import { useNavigate, Link } from 'react-router-dom';

const UsersList = () => {
  const [users, setUsers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loggedInUserRole, setLoggedInUserRole] = useState('');
  const [loggedInUserEmail, setLoggedInUserEmail] = useState('');
  const [university, setUniversity] = useState('')
  const db = getFirestore();

  useEffect(() => {
    // Get role and email of the logged-in user from localStorage
    const role = localStorage.getItem('loggedInUserRole');
    const email = localStorage.getItem('loggedInUserEmail');
    setLoggedInUserRole(role);
    setLoggedInUserEmail(email);

    setUniversity(localStorage.getItem('university'))

    const fetchUsers = async () => {
      let usersCollectionQuery;

      if (role === 'superAdmin') {
        // Fetch users with the role 'universityAdmin' only
        usersCollectionQuery = query(collection(db, 'users'), where('role', '==', 'universityAdmin'));
      } else if (role === 'universityAdmin') {
        // Get the logged-in universityAdmin's university ID
        const userSnapshot = await getDocs(query(collection(db, 'users'), where('email', '==', email)));
        if (!userSnapshot.empty) {
          usersCollectionQuery = query(collection(db, 'users'), where('university', '==', localStorage.getItem('university')));
        }
      }

      if (usersCollectionQuery) {
        const userSnapshot = await getDocs(usersCollectionQuery);
        const userList = userSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setUsers(userList);
      }
    };

    fetchUsers();
  }, []);

  const handleEdit = (user) => {
    setCurrentUser(user);
    setOpenDialog(true);
  };

  const handleCreateUser = () => {
    setCurrentUser(null);
    setOpenDialog(true);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Filter users based on search term
  const filteredUsers = users.filter((user) =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
    (user.university && user.university.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  // Paginate users
  const paginatedUsers = filteredUsers.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <div style={{ padding: '20px', paddingTop: '80px' }}>
      <div style={{ position: 'fixed', top: '20px', left: '20px', display: 'flex', alignItems: 'center' }}>
        <Link to="/">
          <GrFormPreviousLink /> Home
        </Link>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <h2>User List</h2>
        {(loggedInUserRole === 'superAdmin' || users?.length < 5) && <Button
          variant="contained"
          color="primary"
          onClick={handleCreateUser}
        >
          Create User
        </Button>
        }
      </div>

      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={handleSearch}
      />

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>University Name</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedUsers.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.phoneNumber}</TableCell>
                <TableCell>{user.university}</TableCell>
                <TableCell>
                  <Button variant="outlined" onClick={() => handleEdit(user)}>
                    Edit
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={filteredUsers.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* ManageUser dialog */}
      <ManageUser open={openDialog} handleClose={() => setOpenDialog(false)} user={currentUser} />
    </div>
  );
};

export default UsersList;
