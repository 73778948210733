import React, { useState, useEffect } from 'react';
import { jsPDF } from 'jspdf';
import imgPlaceholder from './image_placeholder.png';
import { GrZoomIn, GrZoomOut, GrRotateRight, GrDownload, GrClose, GrFormNext, GrFormPrevious } from "react-icons/gr";

const ImageGallery = ({ pdfPages, ...props }) => {
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [pageSettings, setPageSettings] = useState([]);
    const [scale, setScale] = useState(1); // for zoom
    const [rotation, setRotation] = useState(0); // for rotation

    // Update pageSettings whenever pdfPages change
    useEffect(() => {
        setPageSettings(pdfPages.map(() => ({ scale: 1, rotation: 0 })));
    }, [pdfPages]);

    const openImage = (index) => {
        setSelectedIndex(index);
    };

    const closeImage = () => {
        setSelectedIndex(null);
        setScale(1); // Reset zoom
        setRotation(0); // Reset rotation
    };

    const nextImage = () => {
        if (selectedIndex < pdfPages.length - 1) {
            setSelectedIndex(selectedIndex + 1);
        }
    };

    const prevImage = () => {
        if (selectedIndex > 0) {
            setSelectedIndex(selectedIndex - 1);
        }
    };

    const zoomIn = () => {
        updatePageSetting(selectedIndex, { scale: pageSettings[selectedIndex].scale + 0.2 });
    };

    const zoomOut = () => {
        updatePageSetting(
            selectedIndex,
            { scale: Math.max(0.4, pageSettings[selectedIndex].scale - 0.2) }
        );
    };

    const updatePageSetting = (index, newSettings) => {
        setPageSettings((prev) => {
            const updatedSettings = [...prev];
            updatedSettings[index] = { ...updatedSettings[index], ...newSettings };
            return updatedSettings;
        });
    };

    const rotateClockwise = () => {
        updatePageSetting(selectedIndex, { rotation: pageSettings[selectedIndex].rotation + 90 });
    };

    const getRotatedImage = async (imgSrc, rotation, scale) => {
        return new Promise((resolve) => {
            const image = new Image();
            image.src = imgSrc;
            image.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");

                // Adjust canvas dimensions based on rotation
                if (rotation % 180 === 0) {
                    canvas.width = image.width * scale;
                    canvas.height = image.height * scale;
                } else {
                    canvas.width = image.height * scale;
                    canvas.height = image.width * scale;
                }

                // Rotate and scale image
                ctx.translate(canvas.width / 2, canvas.height / 2);
                ctx.rotate((rotation * Math.PI) / 180);
                ctx.scale(scale, scale);
                ctx.drawImage(image, -image.width / 2, -image.height / 2);

                resolve(canvas.toDataURL("image/jpeg"));
            };
        });
    };

    const downloadAsPDF = async () => {
        const pdf = new jsPDF();
        for (let i = 0; i < pdfPages.length; i++) {
            const { rotation, scale } = pageSettings[i];
            const rotatedImage = await getRotatedImage(pdfPages[i], rotation, scale);
            if (i !== 0) pdf.addPage();
            pdf.addImage(rotatedImage, 'JPEG', 10, 10, 180, 250);
        }
        pdf.save('transcript.pdf');
    };

    return (
        <div style={{ height: props.height, width: '100%' }}>
            {pdfPages.length > 0 && (
                <div className="pdf-grid">
                    {pdfPages.map((page, index) => (
                        <div className="pdf-page" key={index}>
                            <img
                                src={page}
                                alt={`PDF page ${index + 1}`}
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                    cursor: 'pointer',
                                    transform: `scale(${pageSettings[index]?.scale || 1}) rotate(${pageSettings[index]?.rotation || 0}deg)`
                                }}
                                onClick={() => openImage(index)}
                            />
                        </div>
                    ))}
                </div>
            )}

            {pdfPages.length === 0 && (
                <div className='pdf-placeholder-panel'>
                    <img src={imgPlaceholder} alt="Placeholder" />
                    <div style={{ padding: '25px', fontSize: '18px', fontWeight: 300, width: '60%', lineHeight: '1.5', textAlign: 'center' }}>
                        Your transcript will appear here after you upload a PDF. Please ensure the document is clear and contains the information needed for GPA conversion.
                    </div>
                </div>
            )}

            {selectedIndex !== null && (
                <div style={overlayStyles}>
                    <div className="image-gallery-modal" style={modalStyles}>
                        <div className="gallery-content" style={galleryContentStyles}>
                            <button onClick={prevImage} disabled={selectedIndex === 0} style={buttonStyles}><GrFormPrevious /></button>
                            <img
                                src={pdfPages[selectedIndex]}
                                alt={`Preview of page ${selectedIndex + 1}`}
                                style={{
                                    ...imageStyles,
                                    transform: `scale(${pageSettings[selectedIndex].scale}) rotate(${pageSettings[selectedIndex].rotation}deg)`,
                                }}
                            />
                            <button onClick={nextImage} disabled={selectedIndex === pdfPages.length - 1} style={buttonStyles}><GrFormNext /></button>
                        </div>
                        <div style={controlsContainerStyles}>
                            <button onClick={zoomIn} style={controlButtonStyles}><GrZoomIn /></button>
                            <button onClick={zoomOut} style={controlButtonStyles}><GrZoomOut /></button>
                            <button onClick={rotateClockwise} style={controlButtonStyles}><GrRotateRight /></button>
                            <button onClick={downloadAsPDF} style={controlButtonStyles}><GrDownload /></button>
                        </div>
                        <button onClick={closeImage} style={closeButtonStyles}><GrClose /></button>
                    </div>
                </div>
            )}
        </div>
    );
};

// Styles for modal, buttons, and overlay
const overlayStyles = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
};

const modalStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 1001,
};

const galleryContentStyles = {
    display: 'flex',
    alignItems: 'center',
};

const imageStyles = {
    maxHeight: '80vh',
    width: 'auto',
    maxWidth: '90%',
};

const buttonStyles = {
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    border: 'none',
    margin: '0 10px',
};

const closeButtonStyles = {
    position: 'absolute',
    top: '20px',
    right: '20px',
    fontSize: '18px',
    backgroundColor: '#ff0000',
    color: '#fff',
    border: 'none',
    padding: '10px',
    cursor: 'pointer',
};

const controlsContainerStyles = {
    display: 'flex',
    gap: '10px',
    marginTop: '10px',
    zIndex: 9999
};

const controlButtonStyles = {
    padding: '10px',
    fontSize: '14px',
    cursor: 'pointer',
    border: 'none',
    backgroundColor: '#007bff',
    color: '#fff',
    borderRadius: '4px',
};

export default ImageGallery;
