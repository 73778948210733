import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './FileUpload.css';
import { pdfjs } from 'react-pdf';
import ImageGallery from './ImageGallery';
import { calculateCGPA, convertPercentageToGPA, extractAllCGPA, extractAllSGPA, extractCGPAFromNagarjunaUniversity, extractCGPAFromSSC, extractFirstOverallCGPA, extractTotalMarksFromPUC, getCGPAFromPer, getClassification, getPerFromCGPA, getUSClassification } from './utils';
import IndiaGrades from './pages/India';
import { Box, LinearProgress, Typography, Autocomplete, TextField, Button } from '@mui/material';
import { FaRegCopy } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Logo from './assets/WEE_Logo_final.png';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { FaWindowClose } from "react-icons/fa";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const sgpaIcon = require('./assets/sgpa_icon.png');
const cgpaIcon = require('./assets/cgpa.png');
const perIcon = require('./assets/percentage.png');
const gpaIcon = require('./assets/gpa.png');

const countries = [
  { code: 'cn', label: 'China' },
  { code: 'jp', label: 'Japan' },
  { code: 'kr', label: 'South Korea' },
  { code: 'in', label: 'India' },
  { code: 'pk', label: 'Pakistan' },
  { code: 'bd', label: 'Bangladesh' },
  { code: 'vn', label: 'Vietnam' },
  { code: 'ph', label: 'Philippines' },
  { code: 'th', label: 'Thailand' },
  { code: 'my', label: 'Malaysia' },
  { code: 'sg', label: 'Singapore' },
  { code: 'id', label: 'Indonesia' },
  { code: 'lk', label: 'Sri Lanka' },
  { code: 'np', label: 'Nepal' },
  { code: 'mm', label: 'Myanmar' },
  { code: 'kh', label: 'Cambodia' },
  { code: 'la', label: 'Laos' },
  { code: 'mn', label: 'Mongolia' },
  { code: 'af', label: 'Afghanistan' },
  { code: 'ir', label: 'Iran' },
  { code: 'iq', label: 'Iraq' },
  { code: 'ae', label: 'United Arab Emirates' },
  { code: 'sa', label: 'Saudi Arabia' },
  { code: 'qa', label: 'Qatar' },
  { code: 'bh', label: 'Bahrain' },
  { code: 'kw', label: 'Kuwait' },
  { code: 'om', label: 'Oman' },
  { code: 'sy', label: 'Syria' },
  { code: 'lb', label: 'Lebanon' },
  { code: 'jo', label: 'Jordan' },
  { code: 'ye', label: 'Yemen' },
];

const FileUpload = () => {
  const [selectedCountry, setSelectedCountry] = useState(countries.find(country => country.code === 'in'));
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [percentage, setPercentage] = useState('');
  const [loading, setLoading] = useState(false);
  const [extractedText, setExtractedText] = useState('');
  const [gpa, setGpa] = useState('');
  const [valueType, setValueType] = useState('');
  const [pdfPages, setPdfPages] = useState([]); // Store all pages here
  const [numPages, setNumPages] = useState(0);
  const [sgpa, setSGPA] = useState(0);
  const [cgpa, setCGPA] = useState(0);
  const [progress, setProgress] = useState(0);
  const db = getFirestore();
  const [processType, setProcessType] = useState('')
  const [classification, setClassification] = useState('');
  const [usGrade, setUSGrade] = useState('');
  const [university, setUniversity] = useState('')
  const [open, setOpen] = useState(false);

  const handleOpenFormulaPopup = () => setOpen(true);
  const handleCloseFormulaPopup = () => setOpen(false);

  const onFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    loadPdfPages(selectedFile);
  };

  useEffect(() => {
    setUniversityData()
  }, [])

  const setUniversityData = async () => {
    let universityId = localStorage.getItem('university')
    const universityDoc = await getDoc(doc(db, 'universities', universityId));
    if (universityDoc.exists()) {
      const universityData = universityDoc.data();
      setUniversity(universityData)
    }
  }

  const onFileUpload = async () => {
    setExtractedText('');
    setPercentage('');
    setGpa('');
    setMessage('');
    setLoading(true);
    setProcessType('generate')
    const formData = new FormData();
    formData.append('file', file);

    try {
      const res = await axios.post('https://api.worldedueva.com/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      // const res = await axios.post('http://localhost:5000/upload', formData, {
      //   headers: {
      //     'Content-Type': 'multipart/form-data',
      //   },
      // });
      const extractedText = res.data.text;
      //console.log(extractedText)
      setExtractedText(extractedText);
      extractPercentage(extractedText);
      //setPercentage(percentage);
    } catch (err) {
      console.error(err);
      setMessage('Error uploading file. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // const loadPdfPages = async (pdfFile) => {
  //   setLoading(true);
  //   setCGPA('--')
  //   const reader = new FileReader();
  //   reader.onload = async function () {
  //     const typedarray = new Uint8Array(this.result);
  //     const pdf = await pdfjs.getDocument(typedarray).promise;
  //     setNumPages(pdf.numPages);

  //     const pagePreviews = [];

  //     // Iterate through all the pages
  //     for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
  //       const page = await pdf.getPage(pageNum);
  //       const canvas = document.createElement('canvas');
  //       const context = canvas.getContext('2d');
  //       const viewport = page.getViewport({ scale: 1.2 });

  //       canvas.width = viewport.width;
  //       canvas.height = viewport.height;

  //       await page.render({ canvasContext: context, viewport }).promise;

  //       // Store the base64 data of the canvas for each page
  //       pagePreviews.push(canvas.toDataURL());
  //     }

  //     setPdfPages(pagePreviews); // Set all pages into state
  //     setLoading(false);
  //   };
  //   reader.readAsArrayBuffer(pdfFile);
  // };

  const loadPdfPages = async (pdfFile) => {
    setLoading(true);
    setCGPA('');
    setGpa('');
    setPercentage('');
    setProcessType('convert')
    setProgress(0); // Initialize progress state

    const reader = new FileReader();

    reader.onload = async function () {
      const typedarray = new Uint8Array(this.result);
      const pdf = await pdfjs.getDocument(typedarray).promise;
      setNumPages(pdf.numPages);

      const loadPage = async (pageNum) => {
        const page = await pdf.getPage(pageNum);
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        const viewport = page.getViewport({ scale: 1.0 }); // Adjust scale if needed

        canvas.width = viewport.width;
        canvas.height = viewport.height;

        await page.render({ canvasContext: context, viewport }).promise;

        return canvas.toDataURL();
      };

      const pagePreviews = [];

      for (let i = 0; i < pdf.numPages; i++) {
        const pageNum = i + 1;
        const imageData = await loadPage(pageNum);
        pagePreviews.push(imageData);

        // Update progress
        const progress = Math.round(((pageNum / pdf.numPages) * 100));
        setProgress(progress);
      }

      setPdfPages(pagePreviews);
      setLoading(false);
      setProgress(100); // Set to 100% when complete
    };

    reader.readAsArrayBuffer(pdfFile);
  };

  const convertCGPAToGPA = (cgpa) => {
    let per = cgpa * 9.5
    let gpa = convertPercentageToGPA(per)
    return gpa;
  };



  // const extractPercentage = (text) => {
  //   console.log(text)
  //   let matches = extractAllCGPA(text)
  //   if (matches && matches.length > 0) {
  //     let cgpa = calculateCGPA(matches)
  //     setCGPA(cgpa)
  //     let per = getPerFromCGPA(cgpa)
  //     setPercentage(per)
  //     let finalGpa = convertCGPAToGPA(cgpa);
  //     setValueType('CGPA');
  //     setGpa(finalGpa);
  //     return false
  //   }

  //   let overallCGPA = extractFirstOverallCGPA(text)
  //   if (overallCGPA) {
  //     setCGPA(overallCGPA)
  //     let per = getPerFromCGPA(overallCGPA)
  //     setPercentage(per)
  //     let finalGpa = convertCGPAToGPA(overallCGPA);
  //     setValueType('CGPA');
  //     setGpa(finalGpa);
  //     return false
  //   }

  //   let regex = /CGPA\s*(?:Secured)?\s*[:\s]?\s*(\d+\.\d+)/i;
  //   let match = text.match(regex);
  //   let per = 0;
  //   if (match) {
  //     setValueType('CGPA');
  //     let finalGpa = convertCGPAToGPA(parseFloat(match[1]));
  //     setGpa(finalGpa);
  //     setPercentage(getPerFromCGPA(match[1]))
  //     per = match[1]
  //     setCGPA(match[1])
  //   } else {
  //     regex = /MAX\.MARKS[\s\S]*?TOTAL\s+\d+\s+\d+\s+\d+\s+(\d+\.\d+)%/i;
  //     match = text.match(regex);
  //     setValueType('Percentage');
  //     if (match) {
  //       let totalPercentage = match[1];
  //       let finalGpa = convertPercentageToGPA(totalPercentage);
  //       setGpa(finalGpa);
  //       setPercentage(totalPercentage)
  //       setCGPA(getCGPAFromPer(totalPercentage))
  //       per = totalPercentage
  //     } else {
  //       regex = /(\d+\.\d+)%/;
  //       match = text.match(regex);
  //       if (match) {
  //         let finalGpa = convertPercentageToGPA(match[1]);
  //         setGpa(finalGpa);
  //         setPercentage(match[1])
  //         setCGPA(getCGPAFromPer(match[1]))
  //         per = match[1]
  //       } else {
  //         const sgpa = extractAllSGPA(text);
  //         setSGPA(sgpa.join(','))
  //         const cgpa = calculateCGPA(sgpa)
  //         setCGPA(cgpa)
  //         per = getPerFromCGPA(cgpa)
  //         setPercentage(per)
  //         let finalGpa = convertCGPAToGPA(cgpa);
  //         setValueType('CGPA');
  //         setGpa(finalGpa);
  //       }
  //     }

  //     setClassification(getClassification(per))
  //     setUSGrade(getUSClassification(per))
  //   }
  // };

  const extractPercentage = (text) => {
    console.log(text);
    setCGPA('');
    setGpa('');
    setPercentage('');
    // Attempt to extract CGPA if content is from Acharya Nagarjuna University
    const cgpaFromNagarjuna = extractCGPAFromNagarjunaUniversity(text);
    if (cgpaFromNagarjuna) {
      processCGPA(cgpaFromNagarjuna);
      return false;
    }

    // Other extraction methods
    const cgpaMatches = extractAllCGPA(text);
    if (cgpaMatches && cgpaMatches.length > 0) {
      processCGPA(calculateCGPA(cgpaMatches));
      return false;
    }

    const overallCGPA = extractFirstOverallCGPA(text);
    if (overallCGPA) {
      processCGPA(overallCGPA);
      return false;
    }

    const CGPAFromSSC = extractCGPAFromSSC(text);
    if (CGPAFromSSC) {
      processCGPA(CGPAFromSSC);
      return false;
    }

    const TotalMarksFromPUC = extractTotalMarksFromPUC(text);
    if (TotalMarksFromPUC) {
      let percentage = (TotalMarksFromPUC / 1000) * 100;
      processPercentage(parseFloat(percentage));
      return false;
    }

    // Generic CGPA and percentage extraction
    const cgpa = extractPattern(text, /CGPA\s*(?:Secured)?\s*[:\s]?\s*(\d+\.\d+)/i);
    if (cgpa) {
      processCGPA(parseFloat(cgpa));
    } else {
      const percentage = extractPattern(text, /MAX\.MARKS[\s\S]*?TOTAL\s+\d+\s+\d+\s+\d+\s+(\d+\.\d+)%/i) ||
        extractPattern(text, /(\d+\.\d+)%/);
      if (percentage) {
        processPercentage(parseFloat(percentage));
      } else {
        const sgpa = extractAllSGPA(text);
        if (sgpa.length > 0) {
          processCGPA(calculateCGPA(sgpa));
          setSGPA(sgpa.join(','));
        }
      }
    }
  };

  // Helper function to process CGPA
  const processCGPA = (cgpa) => {
    setCGPA(cgpa);
    const percentage = getPerFromCGPA(cgpa);
    setPercentage(percentage);
    setGpa(convertCGPAToGPA(cgpa));
    setValueType('CGPA');
    setClassification(getClassification(percentage));
    setUSGrade(getUSClassification(percentage));
  };

  // Helper function to process Percentage
  const processPercentage = (percentage) => {
    setPercentage(percentage);
    setGpa(convertPercentageToGPA(percentage));
    setCGPA(getCGPAFromPer(percentage));
    setValueType('Percentage');
    setClassification(getClassification(percentage));
    setUSGrade(getUSClassification(percentage));
  };

  // Helper function to extract pattern match from text
  const extractPattern = (text, pattern) => {
    const match = text.match(pattern);
    return match ? match[1] : null;
  };

  const copyToClipboard = (text) => {
    if (!text) {
      toast.error('Nothing to copy');
      return;
    }

    navigator.clipboard.writeText(text)
      .then(() => toast.success(`Copied: ${text}`))
      .catch((error) => {
        console.error('Copy failed:', error);
        toast.error('Failed to copy text');
      });
  };

  return (
    <div style={styles.container}>
      <div style={styles.leftPanel}>
        <div style={{ width: '100%' }}>
          <div style={{ textAlign: 'center' }}>
            {university?.logoUrl && <img src={university?.logoUrl} style={{ maxWidth: '75%', marginTop: '25px', marginBottom: '40px' }} />}
            {!university && <img src={Logo} style={{ maxWidth: '75%', marginTop: '25px', marginBottom: '40px' }} />}
          </div>
          <div>
            <Autocomplete
              id="country-select"
              options={countries}
              getOptionLabel={(option) => option.label}
              value={selectedCountry}
              onChange={(event, newValue) => setSelectedCountry(newValue)}
              renderInput={(params) => (
                <TextField {...params} label="Select Country" variant="outlined" style={{ width: '100%' }} />
              )}
            />
          </div>

          <div style={{ width: '100%' }}>
            <div className="drop-area" style={{ width: '100%' }}>
              <input type="file" id="fileInput" onChange={onFileChange} />
              <div htmlFor="fileInput" className="drop-area-label">
                <div>&#8682; Drop Files or click here</div>
              </div>
              <div className="file-chooser">
                <label htmlFor="fileInput" className="choose-file-button">
                  Choose File
                </label>
              </div>
            </div>
          </div>
          <div style={{ textAlign: 'center' }}>
            <button onClick={onFileUpload} disabled={loading || !file}>
              {loading ? 'Uploading...' : 'Convert to GPA'}
            </button>
          </div>

          <div style={{ textAlign: 'center', marginTop: '60px' }}>
            <Button onClick={handleOpenFormulaPopup} variant="outlined">View GPA Conversion Formulas</Button>
          </div>
        </div>



        {/* Popup with Formula Content */}
        <Dialog open={open} onClose={handleCloseFormulaPopup} maxWidth="lg" fullWidth>
          <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            GPA Conversion Formulas
            <IconButton onClick={handleCloseFormulaPopup} style={{ color: '#555' }}>
              <FaWindowClose />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <IndiaGrades />
          </DialogContent>
        </Dialog>

        <div style={{ position: 'absolute', bottom: 0 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography style={{ color: '#999', marginBottom: '18px' }}>Powered By SAIN Technologies LLC</Typography>
            {/* <img src={Logo} alt="Logo" style={{ width: '150px' }} /> */}
          </div>
        </div>
      </div>

      <div style={styles.rightPanel}>

        <ImageGallery pdfPages={pdfPages} height={gpa ? `calc(100% - 250px)` : `100%`} />

        {gpa && <div style={{ width: '100%' }} >

          <div className='resultCard container'>
            {/* SGPA Block */}
            {/* <div className='resultBlock'>
            <FaRegCopy className='copyIcon' onClick={() => copyToClipboard(sgpa || '--')} />
            <div>
              <div><img src={sgpaIcon} style={{ width: '100px' }} /></div>
              <div className='result'>{sgpa ? sgpa : '--'}</div>
            </div>
          </div> */}

            {/* CGPA Block */}
            <div className='resultBlock'>
              <FaRegCopy className='copyIcon' onClick={() => copyToClipboard(cgpa || '--')} />
              <div>
                <div><img src={cgpaIcon} style={{ width: '100px' }} /></div>
                <div className='result'>{cgpa ? cgpa : '--'}</div>
              </div>
            </div>

            {/* Percentage Block */}
            <div className='resultBlock'>
              <FaRegCopy className='copyIcon' onClick={() => copyToClipboard(percentage ? percentage + '%' : '--')} />
              <div>
                <div><img src={perIcon} style={{ width: '100px' }} /></div>
                <div className='result'>{percentage ? percentage + '%' : '--'}</div>
              </div>
            </div>

            {/* GPA Block */}
            <div className='resultBlock'>
              <FaRegCopy className='copyIcon' onClick={() => copyToClipboard(gpa || '--')} />
              <div>
                <div><img src={gpaIcon} style={{ width: '100px' }} /></div>
                <div className='result'>{gpa ? gpa : '--'}</div>
              </div>
            </div>
          </div>
        </div>
        }
      </div>
      {loading && (
        <div className="overlay">
          <div className="progress-container">
            {/* <LinearProgress height={20} /> */}
            <Box sx={{ width: '80%', textAlign: 'center', marginTop: 2 }}>
              {/* <LinearProgress variant="determinate" value={progress} />
              <Typography variant="body2" color="text.secondary" sx={{ marginTop: 1 }}>
                {`${Math.round(progress)}%`}
              </Typography> */}
              {processType === 'convert' && <Box
                sx={{
                  width: '100%',
                  height: '40px', // Outer container height
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'white', // Outer background color
                  borderRadius: '5px',
                  position: 'relative',
                  overflow: 'hidden',
                }}
              >
                <LinearProgress
                  variant="determinate"
                  value={progress}
                  sx={{
                    height: '20px', // Height of the actual progress bar
                    width: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.1)', // Background color of the track
                    borderRadius: '5px',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: '#3f51b5', // Color of the progress bar
                    },
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    position: 'absolute',
                    color: 'black', // Text color inside the progress bar
                    fontWeight: 'bold',
                    width: '100%',
                    textAlign: 'center',
                  }}
                >
                  {`${progress}%`}
                </Typography>
              </Box>}

              {processType === 'generate' && <Box
                sx={{
                  width: '100%',
                  height: '40px', // Outer container height
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'white', // Outer background color
                  borderRadius: '5px',
                  position: 'relative',
                  overflow: 'hidden',
                }}
              >
                <LinearProgress
                  sx={{
                    height: '20px', // Height of the actual progress bar
                    width: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.1)', // Background color of the track
                    borderRadius: '5px',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: '#3f51b5', // Color of the progress bar
                    },
                  }}
                />

              </Box>}
            </Box>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    height: '100vh',
    fontFamily: 'Arial, sans-serif',
  },
  leftPanel: {
    flex: 0.3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
    backgroundColor: '#ffffff',
    padding: '24px'
  },
  rightPanel: {
    flex: 0.7,
    backgroundColor: 'rgb(84 121 247)',
    color: '#ffffff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    flexDirection: 'column'
  },
  error: {
    color: 'red',
    marginBottom: '16px',
  },
};

export default FileUpload;
