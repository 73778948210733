import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../components/Firebase';
import { useNavigate, Link } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Carousel styles
import loginBanner1 from '../assets/Login_banner_1.png';
import loginBanner2 from '../assets/Login_banner_2.png';
import loginBanner3 from '../assets/Login_banner_3.png';
import Logo from '../assets/WEE_Logo_final.png';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const db = getFirestore();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setError('');
      await signInWithEmailAndPassword(auth, email, password);
      await fetchUserRole(email);
      navigate('/'); // Change this to the route of your FileUpload component
      console.log('Logged in successfully');
    } catch (err) {
      setError('Failed to login. Please check your credentials.');
    }
  };

  const fetchUserRole = async (email) => {
    const userDoc = await getDoc(doc(db, 'users', email));
    if (userDoc.exists()) {
      const userData = userDoc.data();
      localStorage.setItem('loggedInUserRole', userData.role);
      localStorage.setItem('loggedInUserEmail', userData.email);
      localStorage.setItem('university', userData.university);
    } else {
      console.error("User document not found!");
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.leftPanel}>
        <div style={styles.formContainer}>
          <img src={Logo} alt="Logo" style={{ width: '250px' }} />

          <h2 style={styles.heading}>Log in to your Account</h2>
          {error && <p style={styles.error}>{error}</p>}


          <form onSubmit={handleLogin} style={styles.form}>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              style={styles.input}
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              style={styles.input}
            />
            <div style={styles.rememberForgot}>
              {/* <label style={styles.rememberLabel}>
                <input type="checkbox" style={styles.checkbox} /> Remember me
              </label> */}
              <Link to="/forgot-password" style={styles.forgotPasswordLink}>
                Forgot Password?
              </Link>
            </div>
            <button type="submit" style={styles.loginButton}>
              Log in
            </button>
          </form>

          {/* <p style={styles.footerText}>
            Don’t have an account? <Link to="/signup" style={styles.signupLink}>Create an account</Link>
          </p> */}
        </div>
      </div>

      <div style={styles.rightPanel}>
        <Carousel
          showThumbs={false}
          infiniteLoop={true}
          autoPlay={true}
          interval={5000}
          showStatus={false}
          showArrows={false}
          showIndicators={true}
          className="custom-carousel"  // Add a custom class
        >
          <div style={styles.carouselSlide}>
            <img src={loginBanner1} alt="Login Banner" style={styles.bannerImage} />
            <h3 style={styles.rightPanelText}>Upload Your Educational Certificates</h3>
            <p style={styles.rightPanelSubText}>
              Easily upload your certificates in PDF format to begin the verification and evaluation process. Secure and convenient!
            </p>
          </div>
          <div style={styles.carouselSlide}>
            <img src={loginBanner2} alt="Login Banner" style={styles.bannerImage} />
            <h3 style={styles.rightPanelText}>Automated Document Analysis</h3>
            <p style={styles.rightPanelSubText}>
              Our system automates the extraction from uploaded certificates, allowing for seamless and efficient analysis.
            </p>
          </div>
          <div style={styles.carouselSlide}>
            <img src={loginBanner3} alt="Login Banner" style={styles.bannerImage} />
            <h3 style={styles.rightPanelText}>Convert Your Grades to GPA</h3>
            <p style={styles.rightPanelSubText}>
              Effortlessly convert your local grades into an international GPA scale for easy comparisons and applications. Reliable and accurate!
            </p>
          </div>
        </Carousel>

      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    height: '100vh',
    fontFamily: 'Arial, sans-serif',
  },
  leftPanel: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffffff',
  },
  formContainer: {
    width: '100%',
    maxWidth: '400px',
    padding: '40px',
    textAlign: 'center',
  },
  heading: {
    fontSize: '24px',
    color: '#333',
    marginBottom: '8px',
  },
  subHeading: {
    fontSize: '14px',
    color: '#888',
    marginBottom: '24px',
  },
  socialButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '12px',
    marginBottom: '12px',
    backgroundColor: '#f0f0f0',
    borderRadius: '4px',
    border: '1px solid #ddd',
    cursor: 'pointer',
  },
  icon: {
    width: '20px',
    marginRight: '8px',
  },
  orText: {
    fontSize: '12px',
    color: '#888',
    margin: '16px 0',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    padding: '12px',
    marginBottom: '16px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    fontSize: '16px',
  },
  rememberForgot: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '14px',
    color: '#888',
    marginBottom: '16px',
  },
  rememberLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  checkbox: {
    marginRight: '8px',
  },
  forgotPasswordLink: {
    color: 'rgb(84 121 247)',
    textDecoration: 'none',
  },
  loginButton: {
    padding: '12px',
    backgroundColor: 'rgb(84 121 247)',
    color: '#ffffff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
  },
  footerText: {
    fontSize: '14px',
    color: '#888',
    marginTop: '16px',
  },
  signupLink: {
    color: 'rgb(84 121 247)',
    textDecoration: 'underline',
  },
  rightPanel: {
    flex: 1,
    backgroundColor: 'rgb(84 121 247)',
    color: '#ffffff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  carouselSlide: {
    textAlign: 'center',
  },
  bannerImage: {
    width: '80%',
    maxWidth: '500px',
    marginBottom: '24px',
  },
  rightPanelText: {
    fontSize: '24px',
    marginBottom: '16px',
    fontFamily: 'sans-serif'
  },
  rightPanelSubText: {
    fontSize: '16px',
    maxWidth: '100%',
    paddingLeft: '25px',
    paddingRight: '25px',
    margin: '0 auto',
    lineHeight: '22px',
    fontFamily: 'sans-serif'
  },
  error: {
    color: 'red',
    marginBottom: '16px',
  },
};

export default Login;
