// ForgotPassword.js
import React, { useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../components/Firebase'; // Ensure the path is correct
import { Link } from 'react-router-dom';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handlePasswordReset = async (e) => {
        e.preventDefault();
        if (!email) {
            setMessage('');
            setError('Please enter your email address to reset your password.');
            return;
        }
        try {
            await sendPasswordResetEmail(auth, email);
            setMessage('Password reset email sent! Please check your inbox.');
            setError('');
        } catch (err) {
            setError('Failed to send reset email. Please try again.');
            setMessage('');
        }
    };

    return (
        <div style={styles.container}>
            <h2 style={styles.heading}>Forgot Password</h2>
            {error && <p style={styles.error}>{error}</p>}
            {message && <p style={styles.message}>{message}</p>}
            <form onSubmit={handlePasswordReset} style={styles.form}>
                <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    style={styles.input}
                />
                <button type="submit" style={styles.button}>
                    Send Reset Email
                </button>
            </form>
            <Link to="/login" style={styles.loginLink}>
                Login
            </Link>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
    },
    heading: {
        fontSize: '24px',
        marginBottom: '20px',
        color: '#333',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '400px',
    },
    input: {
        padding: '12px',
        marginBottom: '16px',
        borderRadius: '4px',
        border: '1px solid #ccc',
        fontSize: '16px',
    },
    button: {
        padding: '12px',
        backgroundColor: '#007BFF',
        color: '#ffffff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        fontSize: '16px',
    },
    error: {
        color: 'red',
        marginBottom: '16px',
        textAlign: 'center',
    },
    message: {
        color: 'green',
        marginBottom: '16px',
        textAlign: 'center',
    },
    loginLink: {
        marginTop: '16px',
        color: '#007BFF',
        textDecoration: 'underline',
        cursor: 'pointer',
        textAlign: 'center',
    },
};

export default ForgotPassword;
